/* ====== TO TOP ====== */
jQuery(document).ready(function($) {
	var offset = 100,
		offset_opacity = 1200,
		scroll_top_duration = 700,
		$back_to_top = $(".cd-top");
	$(window).scroll(function() {
		$(this).scrollTop() > offset ? $back_to_top.addClass("cd-is-visible") : $back_to_top.removeClass("cd-is-visible cd-fade-out");
		if ($(this).scrollTop() > offset_opacity) {
			$back_to_top.addClass("cd-fade-out");
		}
	});
	$back_to_top.on("click", function(event) {
		event.preventDefault();
		$("body,html").animate(
			{
				scrollTop: 0
			},
			scroll_top_duration
		);
	});
});

/* ====== SLICK SLIDER ====== */
$(".partners").slick({
	infinite: true,
	autoplay: true,
	arrows: true,
	slidesToShow: 6,
	slidesToScroll: 1,
	autoplaySpeed: 7000,
	responsive: [
		{
			breakpoint: 1680,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 1440,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
});

/* ====== NAVIGATION LEVEL 1 ====== */
const $dropdown1 = $(".dropdown");
const $dropdownToggle1 = $(".dropdown-toggle");
const $dropdownMenu1 = $(".dropdown-menu-level1");
const showClass1 = "show";
$(window).on("load resize", function() {
	if (this.matchMedia("(min-width: 768px)").matches) {
		$dropdown1.hover(
			function() {
				const $this = $(this);
				$this.addClass(showClass1);
				$this.find($dropdownToggle1).attr("aria-expanded", "true");
				$this.find($dropdownMenu1).addClass(showClass1);
			},
			function() {
				const $this = $(this);
				$this.removeClass(showClass1);
				$this.find($dropdownToggle1).attr("aria-expanded", "false");
				$this.find($dropdownMenu1).removeClass(showClass1);
			}
		);
	} else {
		$dropdown.off("mouseenter mouseleave");
	}
});
/* ====== NAVIGATION LEVEL 2 ====== */
const $dropdown2 = $(".dropdown-submenu");
const $dropdownToggle2 = $(".dropdown-toggle");
const $dropdownMenu2 = $(".dropdown-menu-level2");
const showClass2 = "show";
$(window).on("load resize", function() {
	if (this.matchMedia("(min-width: 768px)").matches) {
		$dropdown2.hover(
			function() {
				const $this = $(this);
				$this.addClass(showClass2);
				$this.find($dropdownToggle2).attr("aria-expanded", "true");
				$this.find($dropdownMenu2).addClass(showClass2);
			},
			function() {
				const $this = $(this);
				$this.removeClass(showClass2);
				$this.find($dropdownToggle2).attr("aria-expanded", "false");
				$this.find($dropdownMenu2).removeClass(showClass2);
			}
		);
	} else {
		$dropdown.off("mouseenter mouseleave");
	}
});
